import React, { createContext, useContext, useState } from 'react'
import Loader from 'components/Loader'
import { focusInElement } from 'utils/accessibility'

const LoaderContext = createContext({})

export const useLoader = () => {
  const context = useContext(LoaderContext)

  if (!context) {
    throw new Error(
      `useLoader hook must be used within a 'LoaderProvider' component`
    )
  }

  return context
}

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loaderText, setLoaderText] = useState('')

  const showLoader = (text = '') => {
    setIsLoading(true)
    setLoaderText(text)
  }

  const hideLoader = () => {
    setIsLoading(false)

    focusInElement('#logo-header')
  }

  return (
    <LoaderContext.Provider
      value={{
        setIsLoading,
        setLoaderText,
        showLoader,
        hideLoader,
        isLoading,
      }}
    >
      <>
        <Loader isLoading={isLoading} loaderText={loaderText} />
        {React.cloneElement(<div>{children}</div>)}
      </>
    </LoaderContext.Provider>
  )
}
