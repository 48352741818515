import { useEffect, createContext, useState, useContext } from 'react'
import router from 'utils/router'
import storage, { handleStorageError } from 'utils/storage'
import isClient from 'utils/isClient'
import themesVariables from './themesVariables'

const originThemeMap = {
  1: 'itaucard',
  2: 'crediario',
  3: 'credicard',
  4: 'vitrine',
  5: 'recovery',
  6: 'iti',
  7: 'whatsapp',
  9: 'rede',
  10: 'reneg',
  11: 'pivo',
  12: 'hub',
}

const handleSetColorVariables = (theme) => {
  Object.keys(theme).forEach((variable) => {
    const property = `--${variable}`
    const value = theme[variable]

    document.documentElement.style.setProperty(property, value)
  })
}

const handleGetItauThemeFromUtmSource = (utmSource) => {
  const validSources = ['superapp-itau']

  if (validSources.includes(utmSource)) return 'reneg'
}

export const ThemeContext = createContext({
  theme: 'recovery',
  setTheme: () => null,
})

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    () =>
      router.getQueryString('theme') ||
      originThemeMap[router.getQueryString('origin')] ||
      handleGetItauThemeFromUtmSource(router.getQueryString('utm_source')) ||
      handleStorageError(storage.session.getItem('theme'), 'recovery') ||
      'recovery'
  )

  const handleSetTheme = (theme) => {
    const themeValidation = theme === 'itaucard' ? 'reneg' : theme

    setTheme(themeValidation)
    storage.session.setItem('theme', themeValidation)
  }

  useEffect(() => {
    storage.session.setItem('theme', theme)

    const themeValidation = theme === 'itaucard' ? 'reneg' : theme

    const currentTheme = themesVariables[themeValidation]
    handleSetColorVariables(currentTheme)
  }, [theme])

  const values = { theme, setTheme: handleSetTheme }

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context && isClient()) {
    throw new Error(
      `useTheme hook must be used within a 'ThemeProvider' component`
    )
  }

  return context
}
