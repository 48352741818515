import storage from 'utils/storage'
import { generateAndGetCorrelationId } from './correlationId'

export const generateSegmentPageViewPayload = (pageName) => {
  const segmentData = generateSegmentData()

  return {
    pageName,
    ...segmentData,
  }
}

export const generateSegmentData = () => {
  const correlationId = generateAndGetCorrelationId()
  const callback_url = storage.session.getItem('urlCallback')

  return {
    correlationId,
    source_url: window.location.href,
    callback_url,
    isNew: true,
  }
}
