import getMobileOS from '@mobi/utils/browser/getMobileOS'

export const focusInElement = (selector, options = {}) => {
  const timeout = options.timeout || 100
  const preventScroll = options.preventScroll || false

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const el = document.querySelector(selector)
        if (el) {
          el.focus({ preventScroll })
          return resolve(el)
        }
      } catch (error) {
        return reject(error)
      }
    }, timeout)
  })
}

export const dispatchAriaAlert = ({ message, showOnWindow = false }) => {
  return new Promise((resolve) => {
    let myAlert = document.createElement('p')
    myAlert.setAttribute('role', 'alert')
    myAlert.classList.add('dispatch-alert')

    if (showOnWindow) {
      let myAlertText = document.createTextNode(message)

      myAlert.appendChild(myAlertText)
    } else {
      myAlert.setAttribute('aria-label', message)
    }

    document.body.appendChild(myAlert)

    setTimeout(() => {
      document.body.removeChild(myAlert)
      resolve()
    }, 1000)
  })
}

export const removeGatsbyFocusWrapperTabIndex = () => {
  const isAndroid = getMobileOS() === 'Android'

  if (isAndroid) {
    document.getElementById('gatsby-focus-wrapper').removeAttribute('tabindex')
  }
}

export default {
  focusInElement,
}
