function getSVGDef() {
  if (typeof XMLHttpRequest === 'undefined') {
    return
  }

  const request = new XMLHttpRequest(),
    spritePath = `/sprite.svg?v=`

  const onRequestLoad = () => createSVGDefWrapper(request.responseText)

  request.open('GET', spritePath, true)
  request.send()
  request.onload = onRequestLoad
}

function createSVGDefWrapper(response) {
  const wrapper = document.createElement('div')
  wrapper.id = 'icons-sprite'
  wrapper.innerHTML = response
  document.body.insertBefore(wrapper, document.body.childNodes[0])
}

getSVGDef()
