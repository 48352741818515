import format from '@mobi/utils/formatters/number'
import dateFormat from '@mobi/utils/formatters/date'

const MyAgreementInfo = ({
  agreement_id = String(),
  contract_number = String(),
  group_name = String(),
  fund_name = String(),
  agreement_status = String(),
  agreement_value = Number(),
  wallet_id = String(),
  due_date = String(),
  first_installment_due_date = String(),
  current_installment = Number(),
  installments_number = Number(),
  installment_value = Number(),
  fee = Number(),
  pix_code = String(),
  bar_code = String(),
  bank_slip = String(),
} = {}) => ({
  id: agreement_id,
  name: group_name,
  contractNumber: contract_number,
  fundTitle:
    fund_name === 'IResolve Companhia Securitizadora'
      ? 'Dívidas cedidas à IResolve'
      : `Dívidas cedidas ao ${fund_name}`,
  fundTooltipText: fund_name?.includes('NPL')
    ? 'Fundo de Investimento em Direitos Creditórios não Padronizados NPL II que, por meio de uma cessão de crédito, adquiriu o direito de cobrança e recebimento de operação (ões) financeiras e que hoje estão sob gestão da Recovery, empresa especializada em cobrança.'
    : 'Iresolve Companhia Securitizadora de Créditos Financeiros S.A., que, por meio de uma cessão de crédito, adquiriu o direito de cobrança e recebimento de operação (ões) financeiras e que hoje estão sob gestão da Recovery, empresa especializada em cobrança.',
  status: agreement_status,
  value: format({ value: agreement_value, style: 'currency' }),
  walletId: wallet_id,
  dueDate: dateFormat(due_date),
  firstInstallmentDueDate: dateFormat(first_installment_due_date),
  currentInstallment: current_installment,
  installmentsNumber: installments_number,
  installmentValue: format({
    value: installment_value,
    style: 'currency',
  }),
  fee: `${fee}%`,
  pixCode: pix_code,
  barCode: bar_code,
  bankSlip: bank_slip,
})

export default MyAgreementInfo
