import { useEffect } from 'react'
import * as classNames from 'classnames'
import Text from 'components/Text'
import { dispatchAriaAlert } from 'utils/accessibility'
import './Loader.scss'

const Loader = ({ isLoading, loaderText }) => {
  useEffect(() => {
    if (isLoading) {
      dispatchAriaAlert({ message: loaderText || 'Carregando...' })
    }
  }, [isLoading])

  return (
    <div
      className={classNames('loader', {
        '-hide': !isLoading,
      })}
      aria-label={loaderText || 'Carregando'}
      aria-live="polite"
      aria-busy="true"
      tabIndex="0"
    >
      <div className="loader-wrapper">
        <svg className="loader-circular" viewBox="25 25 50 50">
          <circle
            className="circle"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
            data-contrast="loader"
          />
        </svg>
        {loaderText && (
          <Text p size={12} className="loader-wrapper__text">
            {loaderText}
          </Text>
        )}
      </div>
    </div>
  )
}

export default Loader
