import format from '@mobi/utils/formatters/number'
import dateFormat from '@mobi/utils/formatters/date'
import { capitalizeAll } from 'utils/string'

export const DebtGroupBFF = (group = {}) => {
  const { debt_fund, origins, wallet_id, fund_id } = group.CustomData
  const { PaymentOptions: rawPaymentOptions } = group.debt_groups[0]

  const isDesenrola = origins?.some((origin) => origin.is_desenrola) ?? false

  const paymentOptions = sortPaymentOptions(
    rawPaymentOptions?.map(PaymentOptionBFF)
  )

  const paymentDays = handlePaymentDays(group.PaymentDays)

  const hotDealOption = paymentOptions?.find((option) => option.isBestOffer)
  const suggesttedPaymentOption = hotDealOption || paymentOptions[0]

  const simulationHash = group.debt_groups[0].SimulationHash

  return {
    id: group.ExternalContractID,
    name: group.GroupName,
    fund:
      debt_fund === 'IResolve Companhia Securitizadora'
        ? 'IResolve'
        : debt_fund,
    fundTooltipText: debt_fund?.includes('NPL')
      ? 'Fundo de Investimento em Direitos Creditórios não Padronizados NPL II que, por meio de uma cessão de crédito, adquiriu o direito de cobrança e recebimento de operação (ões) financeiras e que hoje estão sob gestão da Recovery, empresa especializada em cobrança.'
      : 'Iresolve Companhia Securitizadora de Créditos Financeiros S.A., que, por meio de uma cessão de crédito, adquiriu o direito de cobrança e recebimento de operação (ões) financeiras e que hoje estão sob gestão da Recovery, empresa especializada em cobrança.',
    originalValue: format({ value: group.DebtValue, style: 'currency' }),
    originalValueRaw: group.DebtValue,
    debtOrigins: origins?.map(DebtOriginBFF),
    paymentOptions,
    inCash: paymentOptions[0],
    installmentOffer:
      suggesttedPaymentOption.installmentNumber === 1
        ? handleInstallmentOffer(paymentOptions)
        : suggesttedPaymentOption,
    dueDateSelected: {
      date: paymentDays.suggestion,
      payment: suggesttedPaymentOption,
    },
    walletId: wallet_id,
    paymentDays,
    isDesenrola,
    simulationHash,
    fundId: fund_id,
  }
}

const NegativeDebtOrigin = ({
  negative_debt_source = String(),
  negative_debt_date = String(),
}) => ({
  negativeDebtSource: negative_debt_source,
  negativeDebtDate: negative_debt_date,
})

export const DebtOriginBFF = ({
  origin = '',
  value = 0,
  contract_number = '',
  judiciary_processes,
  debt_date = '',
  // debt_type,
  delay_days = '',
  // original_value,
  is_negative_debt = false,
  negative_debt_origins = null,
  operation_id = null,
}) => ({
  name: capitalizeAll(origin),
  value: format({ value: value, style: 'currency' }),
  contractNumber: contract_number.replace(/\D/g, ''),
  judiciaryProcesses:
    judiciary_processes === null
      ? ''
      : judiciary_processes
          .filter((judiciaryProcess) => judiciaryProcess !== '')
          .map((judiciaryProcess) => judiciaryProcess.replace(/\D/g, '')),
  delayDays: delay_days,
  debtDate: dateFormat(debt_date),
  isNegativeDebt: is_negative_debt,
  negativeDebtOrigins:
    negative_debt_origins === null
      ? []
      : negative_debt_origins.map(NegativeDebtOrigin),
  operationId: operation_id,
})

const PaymentOptionBFF = ({
  payment_option_id = '',
  installment_number = 0,
  installment_value = 0,
  discount_rate = 0,
  discount_value = 0,
  value = 0,
  interest_rate = 0,
  is_best_offer = false,
  is_hot_deal,
}) => ({
  id: payment_option_id,
  installmentNumber: installment_number,
  installmentValue: format({
    value: Math.floor(installment_value * 100) / 100,
    style: 'currency',
  }),
  installmentValueRaw: installment_value,
  discount: `${parseInt(discount_rate)}%`,
  discountRaw: discount_rate,
  discountParsed: parseInt(discount_rate),
  discountValue: format({
    value: Math.floor(discount_value * 100) / 100,
    style: 'currency',
  }),
  discountValueRaw: discount_value,
  value: format({ value: Math.floor(value * 100) / 100, style: 'currency' }),
  valueRaw: value,
  interestRate: `${interest_rate}%`.replace('.', ','),
  isBestOffer: is_hot_deal || is_best_offer,
})

const handleInstallmentOffer = (paymentOptions) => {
  const payment3XOption = paymentOptions.find(
    (paymentOption) => paymentOption.installmentNumber === 3
  )
  if (payment3XOption) return payment3XOption

  return paymentOptions[paymentOptions.length - 1]
}

const handlePaymentDays = (paymentDays) => {
  const orderedPaymentDays = paymentDays.sort().map((date) => {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  })

  return {
    suggestion: orderedPaymentDays[4],
    minimumPaymentDay: orderedPaymentDays[0],
    maximumPaymentDay: orderedPaymentDays[orderedPaymentDays.length - 1],
    options: orderedPaymentDays,
  }
}

const sortPaymentOptions = (paymentOptions) => {
  return paymentOptions.sort((a, b) => {
    if (a.installmentNumber < b.installmentNumber) return -1
    if (a.installmentNumber > b.installmentNumber) return 1
  })
}

export const handleSimulatedContracts = (contracts, simulations) => {
  return contracts.map((contract) => {
    const { ExternalContractID } = contract
    const contractSimulation = simulations.find((simulation) => {
      const debtGroup = simulation.debt_groups[0]

      return ExternalContractID === debtGroup.DebtGroupID
    })

    return {
      ...contract,
      ...contractSimulation,
    }
  })
}
