import { useEffect, useState } from 'react'
import { getVWOVariant } from 'utils/getVWOVariant'
import router from 'utils/router'

/**
 * @param {string} name o nome do experimento setado no JavaScript da plataforma VWO, ex: "tela_conclusao_card"
 * @param {number} tries o número de tentativas que a função irá fazer para tentar pegar o valor da variante, ex: 10
 * @returns {string} ex: "1"
 */

const useGetVWOVariant = ({
  name,
  tries = 30,
  interval = 100,
  ignoreCrossSell = true,
}) => {
  const [variantValue, setVariantValue] = useState('loadingExperiment')

  useEffect(() => {
    handleGetVariant()
  }, [])

  const handleGetVariant = async () => {
    try {
      const queryUtmMedium = router.getQueryString('utm_medium')
      const isCrossSell = queryUtmMedium === 'cross-sell'

      if (isCrossSell && ignoreCrossSell) {
        setVariantValue('')
        return
      }

      const response = await getVWOVariant({ name, tries, interval })
      setVariantValue(response)
    } catch (error) {
      setVariantValue('')
      console.error('error to get VWO variant --', error)
    }
  }

  return variantValue
}

export default useGetVWOVariant
