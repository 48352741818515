import {
  SYSTEM_TOKEN,
  SYSTEM_TOKEN_RECOVERY_SECONDARY,
  SYSTEM_TOKEN_REDE,
  SYSTEM_TOKEN_PIVO,
  SYSTEM_TOKEN_LOJAS_AVENIDA,
  SYSTEM_TOKEN_RENEG,
  SYSTEM_TOKEN_BFF_RECOVERY,
  SYSTEM_TOKEN_BFF_REDE,
  SYSTEM_TOKEN_BFF_PIVO,
  SYSTEM_TOKEN_BFF_LOJAS_AVENIDA,
  SYSTEM_TOKEN_BFF_ITAU_CARTOES,
  SYSTEM_TOKEN_BFF_HUB,
  SYSTEM_TOKEN_BFF_ITI,
  SYSTEM_TOKEN_BFF_IQ,
  SYSTEM_TOKEN_BFF_SUPERAPP,
  SYSTEM_TOKEN_SUPERAPP,
} from 'gatsby-env-variables'
import storage from 'utils/storage'
import { isSourceOrigin } from './isSourceOrigin'

const getByOrigin = () => {
  if (isSourceOrigin('pivo')) return 'pivo'
  if (isSourceOrigin('superapp-itau')) return 'superapp'
  if (storage.local.getItem('isLojasAvenida') === true) return 'lojasAvenida'
}

const getBySource = () =>
  getByOrigin() || storage.session.getItem('theme') || 'recovery'

export const getSystemToken = async () => {
  const systemTokens = {
    recovery: SYSTEM_TOKEN,
    itaucard: SYSTEM_TOKEN_RECOVERY_SECONDARY,
    rede: SYSTEM_TOKEN_REDE,
    pivo: SYSTEM_TOKEN_PIVO,
    lojasAvenida: SYSTEM_TOKEN_LOJAS_AVENIDA,
    superapp: SYSTEM_TOKEN_SUPERAPP,
  }

  return getBySource() ? systemTokens[getBySource()] : systemTokens.recovery
}

export const getSystemTokenBFF = async () => {
  const systemTokensBFF = {
    recovery: SYSTEM_TOKEN_BFF_RECOVERY,
    reneg: SYSTEM_TOKEN_RENEG,
    itaucard: SYSTEM_TOKEN_RENEG,
    rede: SYSTEM_TOKEN_BFF_REDE,
    pivo: SYSTEM_TOKEN_BFF_PIVO,
    lojasAvenida: SYSTEM_TOKEN_BFF_LOJAS_AVENIDA,
    cartoes: SYSTEM_TOKEN_BFF_ITAU_CARTOES,
    hub: SYSTEM_TOKEN_BFF_HUB,
    iti: SYSTEM_TOKEN_BFF_ITI,
    iq: SYSTEM_TOKEN_BFF_IQ,
    superapp: SYSTEM_TOKEN_BFF_SUPERAPP,
  }

  return getBySource()
    ? systemTokensBFF[getBySource()]
    : systemTokensBFF.recovery
}
