import { getGoogleIdFormatted, getCookie } from 'utils/cookies'
import storage from 'utils/storage'
import router from 'utils/router'
import { generateAndGetCorrelationId } from './trackings/correlationId'

const getAnalyticsContent = (sourceUrl = false) => {
  const analyticsUuid = storage.session.getItem('analyticsUuid')

  const { getQueryString } = router

  const googleId = getCookie('_ga')
  const userAnalytics = {
    source_url: sourceUrl || window.location.href,
    referrer_url: document.referrer,
    user_agent: navigator.userAgent,
    google_id: getGoogleIdFormatted(googleId),
    facebook_click_id: getCookie('_fbc'),
    facebook_browser_id: getCookie('_fbp'),
    gclid: getQueryString('gclid'),
    tiktok_click_id: getQueryString('ttclid'),
    anonymous_id: window?.analytics?.user
      ? window.analytics?.user()?.anonymousId()
      : '',
    analytics_uuid: analyticsUuid,
    correlation_id: generateAndGetCorrelationId(),
  }

  return userAnalytics
}

export default getAnalyticsContent
