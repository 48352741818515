import Agreement, { AgreementDetails } from './Agreement'
import User from './User'
import MyAgreementInfo from './MyAgreementInfo'

export default {
  Agreement,
  AgreementDetails,
  User,
  MyAgreementInfo,
}
