import React, { useContext, useState } from 'react'
import isClient from '@mobi/utils/browser/isClient'
import Notify from '../components/Notify'

export const NotifyContext = React.createContext({})

export const NotifyProvider = ({ children }) => {
  const [notify, setNotify] = useState(null)

  return (
    <NotifyContext.Provider value={{ setNotify, notify }}>
      <Notify notify={notify} />
      {children}
    </NotifyContext.Provider>
  )
}

export const useNotify = () => {
  const context = useContext(NotifyContext)

  if (!context && isClient()) {
    throw new Error(
      `useNotify hook must be used within a 'NotifyProvider' component`
    )
  }

  return context
}
