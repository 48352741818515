import { navigate } from 'gatsby'
import { signinIntegradora } from 'services/signin'
import storage from 'utils/storage'
import router from './router'

const handleTokens = async (tokensId) => {
  try {
    if (!tokensId) return null

    const { http: apiIntegradora } = await signinIntegradora()

    const tokens = await apiIntegradora.get(`/tokens/${tokensId}`)

    const {
      recovery: { access_token: tokenRecovery },
      bff: { access_token: tokenBff, refresh_token: refreshTokenBff },
    } = tokens

    if (tokenBff) {
      storage.session.setItem('accessToken', tokenBff)
    }

    if (refreshTokenBff) {
      storage.session.setItem('refreshToken', refreshTokenBff)
    }

    if (tokenRecovery) {
      storage.session.setItem('session_token', tokenRecovery)
    }

    return {
      tokenRecovery: tokenRecovery || null,
      tokenBff: tokenBff || null,
      refreshTokenBff: refreshTokenBff || null,
    }
  } catch (error) {
    console.error('error getting tokens --', error)
    navigate('/registro')

    return {
      tokenRecovery: null,
      tokenBff: null,
      refreshTokenBff: null,
    }
  }
}

export const handleValidateTokens = async () => {
  const tokensId = router.getQueryString('tokens_id')

  if (tokensId) {
    const tokens = await handleTokens(tokensId)
    if (!tokens) return null

    return tokens
  }

  return {
    tokenRecovery: null,
    tokenBff: null,
    refreshTokenBff: null,
  }
}

export default handleTokens
