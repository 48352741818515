const themes = {
  recovery: {
    'link-color': '#3F71F0',
    'primary-color': '#001A8A',
    'primary-color-lighter': '#406EE2',
    'secondary-color': '#001A8A',
    'terciary-color': '#158484',
    'stepper-color': '#65C9CB',
    'primary-text-color': '#53555A',
    'hero-label-color': '#001A8A',
    'hero-main-text-color': '#000',
    'hero-button-text-color': '#fff',
    'hero-background-color-start': '#fff',
    'hero-background-color-end': '#fff',
    'hero-button-background-color': '#406EE2',
    'hero-button-background-hover-color': '#406EE2',
    'hero-main-text-highlight-color': '#406EE2',
    'button-hover-background-color': '#2b59d0',
    'header-background-color': '',
    'header-background-color-start': '#001A8A',
    'header-background-color-end': '#001A8A',
    'header-button-border-color': '#9ef4f7',
    'whos-recovery-background-color': '#b7fdff',
    'agreement-header-background-start': '#3E62F4',
    'agreement-header-background-end': '#001190',
    'express-ticket-background-color': '#ECF4F9',
    'footer-background-color': '#001A8A',
    'header-intern-background-color-start': '#fff',
    'header-intern-background-color-end': '#fff',
    'header-intern-border-bottom': '#bdb2af',
  },
  reneg: {
    'link-color': '#FF6200',
    'primary-color': '#FF6200',
    'primary-color-lighter': '#FF6200',
    'secondary-color': '#252220',
    'terciary-color': '#00885A',
    'stepper-color': '#ec7000',
    'primary-text-color': '#605751',
    'hero-label-color': '#f40000',
    'hero-main-text-color': '#f40000',
    'hero-button-text-color': '#f40000',
    'hero-background-color-start': '#fff',
    'hero-background-color-end': '#fff',
    'hero-button-background-color': '#f40000',
    'hero-button-background-hover-color': '#f40000',
    'hero-main-text-highlight-color': '#f40000',
    'button-hover-background-color': '#ce6100',
    'header-background-color': '#F5F3F2',
    'header-background-color-start': '#FF6200',
    'header-background-color-end': '#FF6200',
    'header-button-border-color': '#f40000',
    'whos-recovery-background-color': '#fff',
    'agreement-header-background-start': '#FF6200',
    'agreement-header-background-end': '#FF6200',
    'express-ticket-background-color': '#fff',
    'footer-background-color': '#FF6200',
    'header-intern-background-color-start': '#ec7000',
    'header-intern-background-color-end': '#FF6200',
    'header-intern-border-bottom': '#FF6200',
  },
}

export default themes
