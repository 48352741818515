import isClient from '@mobi/utils/browser/isClient'

let hasInitialized = false

const handleError = () =>
  console.log(`\x1b[31m GA4 - Error on load script \x1b[0m`)

const setup = (isInitialization = false) => {
  return new Promise((resolve, reject) => {
    if (isClient()) {
      let attempts = 0
      const intervalId = setInterval(() => {
        const hasSetted = Boolean(window?.gtag)
        const canResolve = hasSetted && (isInitialization || hasInitialized)
        if (canResolve) {
          console.log(`\x1b[32m GA4 - Script loaded \x1b[0m`)
          clearInterval(intervalId)
          hasInitialized = true
          resolve()
        }

        attempts++
        if (attempts === 100) {
          clearInterval(intervalId)
          reject()
        }
      }, 100)
    } else reject()
  })
}

const initGA4 = async () => {
  try {
    return await setup(true)
  } catch (_) {
    handleError()
  }
}

const track = async (payload) => {
  try {
    await setup()

    const eventName = payload?.analytics?.event_name
    const eventParams = payload?.analytics?.parameters || {}

    if (eventName) {
      console.log(`%c @analytics/ga - ${eventName}`, 'color: red', eventParams)
      return window?.gtag('event', eventName, {
        send_to: 'G-9WE0CQLPEY',
        ...eventParams,
      })
    }
  } catch (_) {
    handleError()
  }
}

export default { initGA4, track }
