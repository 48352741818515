import { getSystemTokenBFF } from 'utils/systemTokens'
import signin, { signinIntegradora } from '../signin'

const unsubscribe = (type, id) => {
  const payload = {
    event: type, //"unsubscribe_website" para quando fechar o acordo, "unsubscribe" pela tela de unsubscribe
    data: {
      person_uuid: id,
    },
  }

  return signin('hermes').then(({ http }) => {
    return http.post('/', payload).then((data) => {
      console.log(data)
      return data
    })
  })
}

const startWorkflow = async ({ accessToken, workflowName }) => {
  const systemToken = await getSystemTokenBFF()

  return signinIntegradora()
    .then(({ http }) => {
      http.defaults.headers['Token'] = `Bearer ${accessToken}`
      http.defaults.headers['SystemToken'] = systemToken

      return http
        .get(`/start-workflow/${workflowName}`)
        .then((res) => {
          return res
        })
        .catch((error) => {
          console.error('startWorkflow error -', error)
        })
    })
    .catch((error) => {
      console.error('error using startWorkflow -', error)
    })
}

export default {
  unsubscribe,
  startWorkflow,
}
