import storage from 'utils/storage'
import ObjectHelper from 'utils/objects'
import router from 'utils/router'

const formatOrigin = () => {
  const callbackUrl = storage.local.getItem('callbackUrl')

  const theme = storage.session.getItem('theme')

  const checkOrigin = (possibleOriginsList) => {
    return [callbackUrl, theme].filter((item) =>
      possibleOriginsList.includes(item)
    ).length
  }

  // Cross Sell Reneg
  if (checkOrigin(['renegociacao', 'reneg'])) {
    return 'Renegociação Itaú'
  }

  // Cross Sell Vitrine
  if (checkOrigin(['emprestimo', 'stg-loans', 'vitrine', 'shopping'])) {
    return 'Shopping Vitrine'
  }

  // Cross Sell Cartões
  if (checkOrigin(['cartoes', 'itaucard', 'credicard', 'iti', 'crediario'])) {
    return 'Cartões'
  }

  // Default Recovery
  if (checkOrigin(['gruporecovery', 'stg-recovery'])) {
    return 'Recovery'
  }

  return ''
}

const pageLoadSnippets = {
  ['PorQueRenegociarDividas']: () => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      transaction: {
        name: formatOrigin(),
      },
    }
  },
  ['PaginadeOferta']: () => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      transaction: {
        name: formatOrigin(),
      },
    }
  },
  ['SelecaodePagamentoeDatadeVencimento']: () => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      transaction: {
        name: formatOrigin(),
      },
    }
  },
  ['ConfirmacaoDosDetalhesDoAcordo']: () => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      transaction: {
        name: formatOrigin(),
      },
    }
  },
  ['DetalhesAcordo']: () => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      transaction: {
        name: formatOrigin(),
      },
    }
  },
  ['FinalizacaodoAcordo']: ({
    originalValueRaw,
    dueDateSelected,
    agreementId,
    partnerId,
  }) => {
    return {
      site: {
        negocio: 'PF',
        tipoDeCanal: 'Web',
      },
      rule: 'pageLoad',
      custom: {
        volsim: originalValueRaw,
        vba: dueDateSelected.payment.valueRaw,
        idproposta: agreementId,
      },
      visitor: {
        partnerid: partnerId,
      },
      transaction: {
        name: formatOrigin(),
      },
    }
  },
}

export const createPageLoad = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:Recovery:${pageName}`
  const snippet = pageLoadSnippets[pageName]
  const payloadFromSnippet = snippet ? snippet(dynamicData) : {}

  const getCleanedUrlToGa = () => {
    const params = new URLSearchParams(window.location.search)
    const step = `${params.get('step') ? `?step=${params.get('step')}` : ''}`
    const urlFinal = `${window.location.origin}${window.location.pathname}${step}`

    return urlFinal
  }

  const defaultPayload = {
    rule: 'pageLoad',
    site: {
      nome: 'IT',
      ambiente: 'NL',
    },
    page: {
      secao: 'Recovery',
      nome: name,
      url: getCleanedUrlToGa(),
    },
    custom: {
      squadresponsaveltag: 'Recovery:Squad Canais Proprios',
    },
  }

  const payload = ObjectHelper.mergeDeepObject(
    payloadFromSnippet,
    defaultPayload
  )
  return payload
}

export const createGA4ScreenView = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:Recovery:${pageName}`

  const getCleanedUrlToGa = () => {
    const params = new URLSearchParams(window.location.search)
    const step = `${params.get('step') ? `?step=${params.get('step')}` : ''}`
    const urlFinal = `${window.location.origin}${window.location.pathname}${step}`

    return urlFinal
  }

  const defaultPayload = {
    analytics: {
      event_name: 'screen_view',
      parameters: {
        screen_name: name,
        custom_path: getCleanedUrlToGa(),
        implementation_team: 'Recovery:SquadCanaisProprios',
        ...dynamicData,
      },
    },
  }

  return defaultPayload
}

export const createGA4Visualization = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:Recovery:${pageName}`

  const getCleanedUrlToGa = () => {
    const params = new URLSearchParams(window.location.search)
    const step = `${params.get('step') ? `?step=${params.get('step')}` : ''}`
    const urlFinal = `${window.location.origin}${window.location.pathname}${step}`

    return urlFinal
  }

  const defaultPayload = {
    analytics: {
      event_name: 'visualization',
      parameters: {
        screen_name: name,
        custom_path: getCleanedUrlToGa(),
        implementation_team: 'Recovery:SquadCanaisProprios',
        ...dynamicData,
      },
    },
  }

  return defaultPayload
}

export const createGA4PageView = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:Recovery:${pageName}`

  const getCleanedUrlToGa = () => {
    const params = new URLSearchParams(window.location.search)
    const step = `${params.get('step') ? `?step=${params.get('step')}` : ''}`
    const urlFinal = `${window.location.origin}${window.location.pathname}${step}`

    return urlFinal
  }

  const defaultPayload = {
    analytics: {
      event_name: 'page_view',
      parameters: {
        page_title: name,
        page_location: getCleanedUrlToGa(),
        implementation_team: 'Recovery:SquadCanaisProprios',
        ...dynamicData,
      },
    },
  }

  return defaultPayload
}

export const createGA4Click = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:Recovery:${pageName}`

  const getCleanedUrlToGa = () => {
    const params = new URLSearchParams(window.location.search)
    const step = `${params.get('step') ? `?step=${params.get('step')}` : ''}`
    const urlFinal = `${window.location.origin}${window.location.pathname}${step}`

    return urlFinal
  }

  const defaultPayload = {
    analytics: {
      event_name: 'click',
      parameters: {
        screen_name: name,
        custom_path: getCleanedUrlToGa(),
        implementation_team: 'Recovery:SquadCanaisProprios',
        ...dynamicData,
      },
    },
  }

  return defaultPayload
}

export const getGTMId = () => {
  const GTM_IDS = {
    itaucard: 'GTM-59L26PJ',
    recovery: 'GTM-NSJJGTX',
    'reneg-itau': 'GTM-WLR7NT3',
  }

  const utmSource = router.getQueryString('utm_source')
  const GTMId = GTM_IDS[utmSource] || GTM_IDS['recovery']

  return GTMId
}
