import { configure } from '@mobi/ds'
import Root from './src/Root'
import analytics, { segment } from '@mobi/libraries/analytics'
import { isSourceOrigin } from 'utils/isSourceOrigin'
import ROUTES from './src/Routes'
import { addNRPagePerformance } from './src/utils/trackings/newRelicEvents'
import { SEGMENT_ID } from 'gatsby-env-variables'
import trackings from './src/utils/trackings'
import { getGTMId } from './src/utils/trackings/gaHelpers'
import ga4 from './src/utils/trackings/ga4'
import datadog from './src/services/datadog'

configure({
  originFonts: 'extern',
  fonts: {
    ItauDisplay: [900],
    ItauText: [400, 700],
  },
  setStyleVariablesInHTML: false,
})

datadog.init()

if (!isSourceOrigin('pivo')) {
  const GTMId = getGTMId()

  // analytics.setup expects a "makeTagular" object. Without it the whole app breaks
  analytics.setup({
    makeTagular: {},
    GTMId,
    isSDKTrackings: true,
    segmentId: SEGMENT_ID,
    trackings,
  })
}

ga4.initGA4()

const getRouteByPathname = (pathname) => {
  const home = {
    path: '/',
    pageName: 'home',
  }

  const route = Object.values(ROUTES).find((route) => route.path === pathname)

  const isHome = pathname === home.path

  return isHome ? { pageName: home.pageName } : route || { pageName: '' }
}

export const onRouteUpdate = async ({ location }) => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]

  const route = getRouteByPathname(location.pathname)

  addNRPagePerformance(route.pageName)
  // Remove the following line after segment pageviews tests
  segment.page('page_view', { pageName: route.pageName })
}

export const wrapRootElement = Root
