import storage from 'utils/storage'
import generateUUID from 'utils/generateUUID'

export const generateAndGetCorrelationId = () => {
  const storagedCorrelatoinId = storage.session.getItem('correlationId')

  if (!storagedCorrelatoinId) {
    const newCorrelationId = generateUUID()
    storage.session.setItem('correlationId', newCorrelationId)

    return newCorrelationId
  }

  return storagedCorrelatoinId
}
