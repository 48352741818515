import generateAuthData from 'utils/generateAuthData'
import storage from 'utils/storage'
// eslint-disable-next-line no-undef
const axios = require('axios')

const auth = (options = {}) => {
  return Promise.resolve()
    .then(getAuthData(options))
    .then(checkExpiration(options))
    .then(setHeaders)
    .then(httpInstance(options))
}

const getAuthData =
  ({ key }) =>
  () => {
    return storage.session.getItem(key)
  }

const checkExpiration =
  ({ url, key, headers }) =>
  (authData) => {
    if (!authData) {
      return getData({ url, key, headers })
    } else {
      const now = new Date().getTime()
      return authData.expires > now ? authData : getData({ url, key, headers })
    }
  }

const setHeaders = (authData) => {
  const token = storage.session.getItem('session_token')
  let headers

  if (token) headers = { Token: `Bearer ${token}` }
  else headers = { Token: `Bearer ${authData.token}` }

  return { authData, headers }
}

const httpInstance =
  (options) =>
  ({ authData, headers }) => {
    const http = axios.create({
      headers: { timeout: 30000, ...headers },
      baseURL: options.baseURL,
    })
    return { authData, headers, http }
  }

const getData = ({ url, headers, key }) => {
  return axios
    .get(url, { headers })
    .then(({ data }) => data)
    .then(saveAuthData(key))
}

const saveAuthData =
  (key) =>
  ({ token }) => {
    const authData = generateAuthData(token)
    storage.session.setItem(key, authData)
    return authData
  }

export default auth
