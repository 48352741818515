export const capitalizeFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const removeAccent = (string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const toPascalCase = (string) => {
  const normalizedString = removeAccent(string)
  return normalizedString.split(' ').map(capitalizeFirst).join('')
}

export const capitalizeAll = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((string) => {
      return string.length > 2 ? capitalizeFirst(string) : string
    })
    .join(' ')
}
