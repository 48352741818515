import router from 'utils/router'
import { getSystemTokenBFF } from 'utils/systemTokens'
import auth from './auth'
import {
  API_ROOT,
  APP_KEY,
  API_INTEGRADORA_ROOT,
  RENEG_APP_KEY,
} from 'gatsby-env-variables'

/**
 *
 * @param {String} context
 * @param {Boolean} withContext
 * @param {Boolean} ignoreHandleError
 * @returns
 */
const signin = (
  context = '',
  withContext = true,
  ignoreHandleError = false
) => {
  return auth({
    key: 'auth',
    url: `${API_ROOT}/authorization`,
    baseURL: withContext
      ? `${API_ROOT}/marketplaces/${context || 'recovery'}/`
      : `${API_ROOT}/marketplaces/`,
    headers: {
      App: APP_KEY,
    },
  }).then((config) => handleHttpRequest(config, ignoreHandleError))
}

export const signinIntegradora = async (ignoreHandleError = false) => {
  return auth({
    key: 'authBFF',
    url: `${API_INTEGRADORA_ROOT}/authorization`,
    baseURL: `${API_INTEGRADORA_ROOT}`,
    headers: {
      App: RENEG_APP_KEY,
      SystemToken: await getSystemTokenBFF(),
    },
  }).then((config) =>
    handleApiBusiness(handleHttpRequest(config, ignoreHandleError))
  )
}

const handleHttpRequest = (config, ignoreHandleError) => {
  config.http.interceptors.request.use((config) => {
    config.timeout = 30000 // same as the APIs
    return config
  })

  config.http.interceptors.response.use(handleSuccess, (error) => {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.error('Request timed out')
    }
    return handleError(error, ignoreHandleError)
  })

  return config
}

const handleApiBusiness = (config) => {
  config.http.interceptors.request.use((config) => {
    const ignoredUrls = ['/lead', '/tokens']
    const isIgnoredUrl = ignoredUrls.some((url) => config.url.includes(url))
    const { useBusinessParam } = config

    if (isIgnoredUrl && !useBusinessParam) return config

    config.params = {
      ...config.params,
      business: 'recovery',
    }
    return config
  })

  return config
}

const handleSuccess = (res) => {
  if (res.data) {
    return res.data
  }
}

const handleError = (error, ignoreHandleError) => {
  if (ignoreHandleError) {
    return Promise.reject(error)
  }

  const { status } = error.response || {}

  if ([400, 401, 503].includes(status)) {
    router.push(`/error`)
  }

  return Promise.reject(error)
}

export default signin
