import isClient from '@mobi/utils/browser/isClient'
import router from 'utils/router'
import storage from 'utils/storage'

export const addNRPageView = async (pageName) => {
  const callNR = () => {
    try {
      const userAnonymized = window?.analytics?.user
        ? window.analytics?.user()?.anonymousId()
        : ''
      /* eslint-disable */
      if (window.newrelic) {
        console.log('NR PageAction', `PageView - ${pageName}`)
        newrelic.addPageAction(`PageView - ${pageName}`, {
          anonymous_id: userAnonymized,
        })
      }
      /* eslint-enable */
    } catch (e) {
      console.error('addNRPageView error-', e)
    }
  }

  if (
    typeof window !== 'undefined' &&
    window.newrelic &&
    window.analytics?.user
  ) {
    return callNR()
  }

  await new Promise((resolve) => {
    setTimeout(() => {
      callNR()
      resolve()
    }, 1500)
  })
}

export const addNRPageAction = async (pageAction, payload = {}) => {
  const callNR = () => {
    try {
      const userAnonymized = window?.analytics?.user
        ? window.analytics?.user()?.anonymousId()
        : ''
      /* eslint-disable */
      if (window.newrelic) {
        console.log('NR PageAction', pageAction, payload)
        newrelic.addPageAction(pageAction, {
          ...payload,
          anonymous_id: userAnonymized,
        })
      }
      /* eslint-enable */
    } catch (e) {
      console.error('addNRPageAction error-', e)
    }
  }

  if (
    typeof window !== 'undefined' &&
    window.newrelic &&
    window.analytics?.user
  ) {
    return callNR()
  }

  await new Promise((resolve) => {
    setTimeout(() => {
      callNR()
      resolve()
    }, 1500)
  })
}

export const handlePerformanceMark = (pageName) => {
  if (typeof window !== 'undefined' && window.performance) {
    window.performance.mark(`page-performance-${pageName}-start`)
  }
}

export const handlePerformanceMeasure = (measureName, startMark, endMark) => {
  try {
    if (typeof window !== 'undefined' && window.performance) {
      const measure = window.performance.measure(
        measureName,
        startMark,
        endMark
      )
      return measure.duration
    }
  } catch (e) {
    // do nothing
  }
}

export const addNRPagePerformance = async (pageName) => {
  try {
    const timeSpent = handlePerformanceMeasure(
      `page-performance-${pageName}`,
      `page-performance-${pageName}-start`
    )

    performance.clearMarks(`page-performance-${pageName}-start`)

    addNRPageAction(`PageNavigationPerformance`, { timeSpent, pageName })
  } catch (e) {
    console.error('addNRPagePerformance error-', e)
  }
}

let hasInitialized = false

const setup = () => {
  return new Promise((resolve, reject) => {
    if (isClient()) {
      let attempts = 0

      const intervalId = setInterval(() => {
        const hasNewRelic = Boolean(window?.newrelic)
        const hasAnonymousId = Boolean(window?.analytics?.initialized)
        const canResolve = (hasNewRelic && hasAnonymousId) || hasInitialized

        if (canResolve) {
          clearInterval(intervalId)
          hasInitialized = true
          resolve()
        }

        attempts++
        if (attempts === 100) {
          clearInterval(intervalId)
          reject(new Error('NRL - Script load attempts exceeded'))
        }
      }, 100)
    } else
      reject(
        new Error(
          'NRL - Script loading is not supported in a non-client environment'
        )
      )
  })
}

export const trackNRPage = async ({
  pageName = '',
  eventType = 'PageView',
  name = '',
  message = '',
}) => {
  try {
    await setup()

    const userAnonymized = window.analytics.user().anonymousId()

    const leadUUID = storage.session.getItem('lead_uuid') || 'unknown'

    const pathName = isClient()
      ? window.location.pathname?.replaceAll('/', '')
      : 'unknown'
    const currentPageName = pageName || pathName

    const currentHref = isClient() ? window.location.href : 'unknown'

    const previousPage = isClient() ? window.previousPath : 'unknown'

    const utmSource =
      storage.session.getItem('utm_source') ||
      router.getQueryString('utm_source') ||
      'unknown'

    console.log('NR trackNRPage', currentPageName)

    window.newrelic
      .interaction()
      .setName(currentPageName)
      .setAttribute('event_type', eventType)
      .setAttribute('anonymous_id', userAnonymized)
      .setAttribute('lead_uuid', leadUUID)
      .setAttribute('name', name)
      .setAttribute('message', message)
      .setAttribute('previousPage', previousPage)
      .setAttribute('origin', utmSource)
      .setAttribute('href', currentHref)
      .save()
  } catch (e) {
    console.log(e)
  }
}
