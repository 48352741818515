import { useEffect, useState } from 'react'
import Text from 'components/Text'
import AlertIcon from '/static/svg/icon-alert.svg'
import LettertIcon from '/static/svg/icon-letter.svg'
import XIcon from '/static/svg/x.svg'
import './Notify.scss'

const Notify = ({ notify }) => {
  const [typeError, setTypeError] = useState('')
  const [message, setMessage] = useState('')
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTypeError(notify?.type)
    setMessage(notify?.message)
    setActive(true)
  }, [notify])
  const icons = [
    {
      type: 'alert',
      icon: <AlertIcon />,
    },
    {
      type: 'success-wpp',
      icon: (
        <img src="/images/logo-wpp.png" alt="whatsapp" aria-hidden={true} />
      ),
    },
    {
      type: 'success',
      icon: <LettertIcon />,
    },
  ]

  return icons
    .filter((item) => item.type === typeError)
    .map(({ type, icon }) => (
      <>
        {message && (
          <div key={type} className={!active ? 'hide' : ''}>
            <div className={`notify -${type}`} data-test={`notify-${type}`}>
              {icon}
              <Text p size={3} className="description">
                {message}
              </Text>
              <div
                className="notify__close"
                onClick={() => setActive(false)}
                aria-label="Fechar"
                role="button"
              >
                <XIcon />
              </div>
            </div>
          </div>
        )}
      </>
    ))
}

export default Notify
