import storage from './storage'
import router from 'utils/router'
import isClient from './isClient'

export const handleSourceOrigin = () => {
  const utmSource = router.getQueryString('utm_source')

  switch (utmSource) {
    case 'pivo':
      storage.local.setItem('is_pivo', true)
      break

    case 'superapp-itau':
      storage.local.setItem('is_superapp', true)
      break

    default:
      break
  }
}

export const isSourceOrigin = (source) => {
  if (!isClient()) return false

  switch (source) {
    case 'pivo':
      return storage.local.getItem('is_pivo')

    case 'superapp-itau':
      return storage.local.getItem('is_superapp')

    default:
      return false
  }
}

export const getPivoUrl = () => {
  return isSourceOrigin('pivo')
    ? storage.session.getItem('startSourceURL')
    : null
}
