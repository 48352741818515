import isClient from 'utils/isClient'
import { navigate } from 'gatsby'
import queryString from 'query-string'

const redirectTo = (url) => (window.location.href = url)

const generateQueryString = (query) => {
  return Object.keys(query).reduce((acc, key) => {
    const value = query[key]
    if (value || value === 0) {
      return acc.concat(`${key}=${value}&`)
    }

    return acc
  }, '')
}

const push = ({ pathname = window.location.pathname, query = {} }) => {
  const queryStr = queryString.stringify(query)
  const queries = queryStr ? `?${queryStr}` : ''
  const newUrl = `${pathname}${queries}`
  navigate(newUrl)
}

const getQueryString = (name) => {
  const queryParam = isClient() && window.location.search
  const params = new URLSearchParams(queryParam)
  return params.get(name)
}

const getQueryParams = (search) => {
  if (!search) return null

  const queries = new URLSearchParams(search).entries()

  const objQueries = [...queries].reduce((obj, item) => {
    const [key, value] = item
    return {
      ...obj,
      [key]: value,
    }
  }, {})

  return objQueries
}

const normalizePathname = (pathname) => {
  if (pathname[pathname.length - 1] === '/') {
    return pathname.slice(0, -1)
  }

  return pathname
}

const getPathName = () => {
  if (isClient()) {
    return normalizePathname(window.location.pathname)
  }

  return ''
}

export default {
  redirectTo,
  generateQueryString,
  getQueryString,
  getQueryParams,
  push,
  getPathName,
}
