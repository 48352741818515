export const getGoogleIdFormatted = (cookie) => {
  if (!cookie) return ''
  const cookieSplitted = cookie.split('.')
  return `${cookieSplitted[2]}.${cookieSplitted[3]}`
}

export const getCookie = (cname) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  let cookieList = decodedCookie.split(';')
  for (let i = 0; i < cookieList.length; i++) {
    let singleCookie = cookieList[i]
    while (singleCookie.charAt(0) == ' ') {
      singleCookie = singleCookie.substring(1)
    }
    if (singleCookie.indexOf(name) == 0) {
      return singleCookie.substring(name.length, singleCookie.length)
    }
  }
  return ''
}
