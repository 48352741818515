import { signinIntegradora } from '../signin'
import { getSystemTokenBFF } from 'utils/systemTokens'

import getAnalyticsContent from 'utils/getAnalyticsContent'

const getLeadUsingHubInfo = async (
  lead_uuid,
  hubToken,
  origin,
  sourceUrlBase64
) => {
  const systemToken = await getSystemTokenBFF()

  const url = `skipper/get-lead`

  const bodyRequest = {
    lead_uuid,
    token: hubToken,
    origin: origin,
    source_url: sourceUrlBase64,
    analytics: getAnalyticsContent(),
  }

  return signinIntegradora()
    .then(({ http }) => {
      http.defaults.headers['SystemToken'] = systemToken
      return http
        .post(url, bodyRequest)
        .then((res) => {
          return res
        })
        .catch((e) => {
          console.error('error using getLeadUsingHubInfo', e)
        })
    })
    .catch((error) => {
      console.error('getHubInfo error -', error)
      throw error
    })
}

const getMarketplaceUrls = async (token) => {
  const systemToken = await getSystemTokenBFF()

  return signinIntegradora()
    .then(({ http }) => {
      http.defaults.headers['Token'] = `Bearer ${token}`
      http.defaults.headers['SystemToken'] = systemToken
      return http
        .get('/skipper/send-lead')
        .then((res) => {
          return res
        })
        .catch((error) => {
          console.error('send lead error -', error)
        })
    })
    .catch((error) => {
      console.error('error using getMarketplaceUrls -', error)
    })
}

export default { getLeadUsingHubInfo, getMarketplaceUrls }
