import { createContext, useContext, useState, useEffect } from 'react'
import analytics from '@mobi/libraries/analytics'
import router from 'utils/router'
import storage from 'utils/storage'
import isClient from 'utils/isClient'
import { useLoader } from './useLoader'
import useGetVWOVariant from 'hooks/useGetVWOVariant'
import services from 'services'
import { globalHistory } from '@reach/router'
import { navigate } from 'gatsby'
import {
  addNRPageAction,
  handlePerformanceMark,
} from 'utils/trackings/newRelicEvents'
import accessibility from 'utils/accessibility'
import * as ROUTES from 'Routes'

const BUTTON_NAMES_CONTROL = {
  0: 'Ver opções de pagamento',
  1: 'fechar acordo',
}
const BUTTON_NAMES_VARIANT = {
  0: 'Ver opções de pagamento',
  1: 'continuar',
  2: 'fechar acordo',
}
const STEPS_CONTROL = ['/ofertas', '/parcelamento', '/conclusao']
const STEPS_VARIANT = [
  '/ofertas',
  '/parcelamento',
  '/escolha-pagamento',
  '/conclusao',
]
const StepsContext = createContext({})

export const StepsProvider = ({ children }) => {
  const paymentChoiceExperiment = useGetVWOVariant({
    name: 'form_pagamento',
  })
  const isPaymentChoiceVariant = paymentChoiceExperiment === 'variant'
  const steps = isPaymentChoiceVariant ? STEPS_VARIANT : STEPS_CONTROL
  const buttonNames = isPaymentChoiceVariant
    ? BUTTON_NAMES_VARIANT
    : BUTTON_NAMES_CONTROL
  const numberOfTotalSteps = steps.length
  const stepsLastIndex = numberOfTotalSteps - 1

  const { showLoader, hideLoader } = useLoader()

  const stepsNumberMap = steps.reduce((acc, step, index) => {
    acc[index] = step
    return acc
  }, {})

  const stepsNameMap = steps.reduce((acc, step, index) => {
    acc[step] = index
    return acc
  }, {})

  const handleStepNumberAndName = (step) => {
    if (typeof step === 'string') {
      return stepsNameMap[step]
    }

    return stepsNumberMap[step]
  }

  const [currentStep, setCurrentStep] = useState(0)

  //* Related to Sender
  const [isButtonNextDisabled, setIsButtonNextDisabled] = useState(false)
  const [buttonName, setButtonName] = useState('')

  // * Related to Groups Selected
  const groupsSelectedSessionStorage = isClient()
    ? storage.session.getItem('groups_selected') || []
    : []

  const [groupsSelected, setGroupsSelected] = useState(
    groupsSelectedSessionStorage
  )

  const sessionId = storage.session.getItem('bff-session_id')
  const debtGroupsData = storage.session.getItem(`${sessionId}-debt-groups`)

  const currentStepQueryString =
    handleStepNumberAndName(router.getPathName()) || 0

  const haveBypass = storage.session.getItem('haveBypass')

  if (haveBypass !== false && currentStepQueryString === 0) {
    const utmSource = !!router.getQueryString('utm_source')
    storage.session.setItem('haveBypass', utmSource)
  }

  useEffect(() => {
    const currentPath = router.getPathName()
    const stepNumber = stepsNameMap[currentPath] || 0
    setCurrentStep(stepNumber)
  }, [])

  useEffect(() => {
    globalHistory.listen(({ location }) => {
      const removeLastSlash = location?.pathname?.replace(/^|\/$/g, '')
      const stepNumber = stepsNameMap[removeLastSlash] || 0

      setCurrentStep(stepNumber)
    })
  }, [isPaymentChoiceVariant])

  useEffect(() => {
    setButtonName(buttonNames[currentStep] || 'próximo')
  }, [currentStep, isButtonNextDisabled])

  useEffect(() => {
    if (currentStep === 0) {
      storage.session.setItem('groups_selected', groupsSelected)

      setIsButtonNextDisabled(groupsSelected?.length === 0)
    }
  }, [isButtonNextDisabled, groupsSelected, currentStep])

  const recoverDebtGroups = async () => {
    try {
      showLoader()

      const sessionId = await services.debt.getSessionId()

      if (!sessionId) {
        handlePerformanceMark(ROUTES.register.pageName)
        navigate('/registro')
      } else if (isClient()) {
        const sessionDebtGroups = await services.debt.getBFFDebtGroups({
          sessionId,
        })

        hideLoader()

        const debtGroups = sessionDebtGroups?.debtGroups
        setGroupsSelected(debtGroups)
        return debtGroups
      }

      hideLoader()
    } catch (e) {
      console.error('recoverDebtGroups error--', e)
    }
  }

  if (!debtGroupsData && currentStep > 0) {
    recoverDebtGroups()
  }

  const checkIfGroupsAreValid = () => {
    const haveSelectedAllDatesAndInstallments = groupsSelected?.filter(
      (item) => {
        const selectEl = document.getElementById(`payment-options-${item.id}`)
        const selectedInstallment = selectEl?.value

        const labelEl = document.getElementById(
          `payment-options-label-${item.id}`
        )
        labelEl.dataset.error = !selectedInstallment

        return (
          item.dueDateSelected.date !== '' &&
          item.dueDateSelected.payment?.id &&
          selectedInstallment
        )
      }
    )

    return haveSelectedAllDatesAndInstallments.length === groupsSelected.length
  }

  const nextStep = async ({ callback } = {}) => {
    const nextStepNumber = currentStep + 1
    const pathName = stepsNumberMap[nextStepNumber]

    try {
      if (pathName === '/parcelamento') {
        showLoader('Carregando opções de pagamento...')
        analytics.track('segmentButtonClick', {
          name: 'OpcoesPagamento',
          id: '05a59370-da0b-4d43-bce8-7f9e4a64b72a',
        })

        const initTime = performance.now()
        await navigate(stepsNumberMap[nextStepNumber])
        const endTime = performance.now()

        const timeSpent = endTime - initTime
        addNRPageAction('PageNavigationPerformance', {
          timeSpent,
          pageName: 'parcelamento',
        })

        setCurrentStep(nextStepNumber)

        const timeToHideLoader = timeSpent > 3000 ? 0 : 3000 - timeSpent

        setTimeout(() => {
          hideLoader()
        }, timeToHideLoader)
      } else if (pathName === '/escolha-pagamento') {
        showLoader('Carregando escolhas de pagamento...')
        setCurrentStep(nextStepNumber)
        await navigate(stepsNumberMap[nextStepNumber])
      } else if (pathName === '/conclusao') {
        if (isPaymentChoiceVariant) {
          const paymentMethod = storage.session.getItem('paymentMethod')
          analytics.track('ga4Click', {
            pageName: 'EscolhaPagamento',
            pageData: {
              id: paymentMethod?.unique,
              detail: paymentMethod?.method,
            },
          })
          analytics.track('segmentButtonClick', {
            name: `EscolhaPagamento:${paymentMethod?.method}`,
            id: paymentMethod?.unique,
          })
        }
        analytics.track('segmentButtonClick', {
          name: 'FecharAcordo',
          id: 'ffbac519-9419-41cc-9321-43b5616c1206',
        })
        const isGroupsValid = checkIfGroupsAreValid()

        if (!isGroupsValid) {
          accessibility.focusInElement(`label[data-error="true"]`)
          return
        }

        showLoader('Concluindo seu acordo...')
        setCurrentStep(nextStepNumber)
        handlePerformanceMark(ROUTES.conclusao.pageName)
        await navigate(stepsNumberMap[nextStepNumber])
      }
    } catch (e) {
      console.error('>> newNextStepError', e)
      setCurrentStep(nextStepNumber)
      handlePerformanceMark(pathName)
      await navigate(pathName)
    } finally {
      if (callback) {
        callback(nextStepNumber)
      }
    }
  }

  const prevStep = ({ callback } = {}) => {
    const prevStepNumber = currentStep - 1
    setCurrentStep(prevStepNumber)

    analytics.track('segmentButtonClick', {
      name: 'Voltar',
      id: '3189485e-abc9-4fd8-8e73-69d0a9f64ee6',
    })

    navigate(stepsNumberMap[prevStepNumber])

    if (callback) {
      callback(prevStepNumber)
    }
  }

  return (
    <StepsContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        isButtonNextDisabled,
        setIsButtonNextDisabled,
        buttonName,
        stepsLastIndex,
        groupsSelected,
        setGroupsSelected,
        stepsNameMap,
        stepsNumberMap,
        userName: debtGroupsData?.userName || '',
      }}
    >
      {children}
    </StepsContext.Provider>
  )
}

export const useSteps = () => {
  const context = useContext(StepsContext)

  if (!context) {
    throw new Error(
      `useSteps hook must be used within a 'StepsProvider' component`
    )
  }

  return context
}
