import removeSpecialChars from '@mobi/utils/formatters/removeSpecialChars'

export const User = ({
  document_number = Number(),
  email = String(),
  name = String(),
  birth_date = String(),
  cellphone = Number(),
  analytics,
}) => ({
  document_number: removeSpecialChars(document_number),
  email: email,
  name: name.trim(),
  birth_date: birth_date.split('/').reverse().join('-'),
  phone_number: removeSpecialChars(cellphone).replace(' ', ''),
  analytics: analytics,
})

export default User
