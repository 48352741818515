import format from '@mobi/utils/formatters/number'
import dateFormat from '@mobi/utils/formatters/date'

export const Agreement = ({ custom_data = {} }) => {
  const { agreements = {} } = custom_data

  return {
    agreementId: agreements.agreement_id,
    debtGroupId: agreements.debt_group_id,
    debtGroupName: agreements.debt_group_name,
    bankSlipUrl: agreements.bank_slip_url,
    barCode: agreements.barcode,
    pixCode: agreements.pix_code,
    pixQRCode: agreements.pix_qr_code,
  }
}

export const AgreementDetails = ({
  agreement_id = 0,
  debt_group_name = '',
  debt_fund_name = '',
  debt_value = 0,
  status = '',
  contract_number = '',
  contract_value = 0,
  due_balance = 0,
  interest_rate = 0,
  delayed_days = 0,
  original_installment_number = 0,
  pending_installment_number = 0,
  first_installment_payment_day = '',
  installment_value = 0,
}) => ({
  agreementId: agreement_id,
  debtGroupName: debt_group_name,
  debtFundName:
    debt_fund_name === 'IResolve Companhia Securitizadora'
      ? 'IResolve'
      : debt_fund_name,
  debtValueRaw: debt_value,
  debtValue: format({ value: debt_value, style: 'currency' }),
  status,
  contractNumber: contract_number,
  contractValueRaw: contract_value,
  contractValue: format({ value: contract_value, style: 'currency' }),
  dueBalanceRaw: due_balance,
  dueBalance: format({ value: due_balance, style: 'currency' }),
  interestRate: `${interest_rate}%`.replace('.', ','),
  delayedDays: delayed_days,
  originalInstallmentNumber: original_installment_number,
  pendingInstallmentNumber: pending_installment_number,
  firstInstallmentPaymentDay: dateFormat(first_installment_payment_day),
  installmentValue: format({ value: installment_value, style: 'currency' }),
})

export default Agreement
