import { segment, ga } from '@mobi/libraries/analytics'
import isClient from 'utils/isClient'
import {
  createGA4PageView,
  createGA4ScreenView,
  createGA4Visualization,
  createGA4Click,
  createPageLoad,
} from './gaHelpers'
import ga4 from './ga4'
import {
  generateSegmentData,
  generateSegmentPageViewPayload,
} from './segmentHelpers'

const trackings = {
  LPPageView: () => {
    const pageData = generateSegmentPageViewPayload('LP')
    segment.track('lp_page_view', pageData)
  },

  segmentPageView: (pageName) => {
    const pageData = generateSegmentPageViewPayload(pageName)
    segment.page('page_view', pageData)
  },
  segmentButtonClick: ({ name, id }) => {
    const segmentData = generateSegmentData()
    const payload = {
      ...segmentData,
      name,
      id,
    }

    segment.track('button_click', payload)
  },
  segmentElementViewed: (elementName) => {
    const segmentData = generateSegmentData()
    const payload = {
      ...segmentData,
      elementName,
    }

    segment.track('element_viewed', payload)
  },
  segmentVWOTest: ({ experimentId, variationName, pageName }) => {
    const defaultPayload = generateSegmentPageViewPayload(pageName)

    segment.track('VWO Test', {
      ...defaultPayload,
      experimentId,
      variationName,
      vwoUserId: isClient() && window?._vwo_uuid,
    })
  },

  gaPageLoad: ({ pageName, pageData }) => {
    const payload = createPageLoad(pageName, pageData)
    const payloadGA4SV = createGA4ScreenView(pageName, pageData)
    const payloadGA4PV = createGA4PageView(pageName, pageData)

    ga.page(payload)
    ga4.track(payloadGA4SV)
    ga4.track(payloadGA4PV)
  },
  gaButtonClick: ({ id, text }) => {
    const payload = {
      events: {
        id: id,
        category: 'Clique',
        action: 'Button',
        label: `BTN:${text}`,
        noInteraction: 'false',
      },
      rule: 'customLink',
    }
    ga.track(payload)
  },
  gaCustomClick: (name) => {
    const payload = {
      events: {
        category: 'Clique',
        action: 'Simulador',
        label: `BTN:MonteSuaProposta:${name}`,
        noInteraction: 'false',
      },
      rule: 'customLink',
    }
    ga.track(payload)
  },
  gaVisualization: (name) => {
    const payload = {
      events: {
        category: 'Alerta',
        action: 'Visualização',
        label: name,
        noInteraction: 'false',
      },
      rule: 'customLink',
    }
    ga.track(payload)
  },

  ga4ScreenView: ({ pageName, pageData }) => {
    const payload = createGA4ScreenView(pageName, pageData)
    ga4.track(payload)
  },
  ga4PageView: ({ pageName, pageData }) => {
    const payload = createGA4PageView(pageName, pageData)
    ga4.track(payload)
  },
  ga4Visualization: ({ pageName, pageData }) => {
    const payload = createGA4Visualization(pageName, pageData)
    ga4.track(payload)
  },
  ga4Click: ({ pageName, pageData }) => {
    const payload = createGA4Click(pageName, pageData)
    ga4.track(payload)
  },
}

export default trackings
