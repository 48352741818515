import Storage from '@mobi/utils/browser/Storage'

import { APP_NAME, ENV } from 'utils/global'

const key = `storage-${APP_NAME}-${ENV}`
const storage = Storage({ prefix: key })

/* Methods
  getItem(item)
  setItem(name, value)
  getLength()
  removeItem(name),
  clearWithPrefix(),
  clear(),
*/

export const handleStorageError = (storageValue, defaultValue) => {
  if (!storageValue) return defaultValue

  if (storageValue instanceof Error) {
    console.error(storageValue)
    return defaultValue
  }

  return storageValue
}

export default storage
